<template>
    <div class="row table-sm mr-0 ml-0 p-0">
        <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
            <div class="col-12 p-0 align-self-center font-weight-bold d-flex align-items-center">
                <!-- <h5 class="d-inline mr-2 font-weight-bold">Пациенты</h5> -->
                <!-- <crm-refresh @c-click="refresh()"></crm-refresh> -->
                <div class="text-center d-flex sorddata col-6 align-items-center" style="margin: 0 auto">
                    <el-input :placeholder="$t('message.patient_or_fio')" prefix-icon="el-icon-search" v-model="filterForm.patient_id" clearable></el-input>
                </div>
            </div>

        </div>
        <table style="background:#fff" class="table table-bordered table-hover" v-loading="loadingData">
            <!-- <crm-pagination :pagination="pagination" @c-change="updatePagination"></crm-pagination> -->
            <thead>
                <tr>
                    <th v-if="columns.id.show" >{{ columns.id.title }}</th>
                    <th v-if="columns.first_name.show" >{{ columns.first_name.title }}</th>
                    <crm-th :sort="sort" :column="columns.settings" @c-change="updateSort"></crm-th>
                </tr>
                <tr>
                    <th v-if="columns.id.show">
                        <el-input disabled clearable size="mini" class="id_input" v-model="filterForm.id"
                            :placeholder="columns.id.title"></el-input>
                    </th>
                    <th v-if="columns.first_name.show">
                        <el-input disabled size="mini" v-model="filterForm.first_name" :placeholder="columns.first_name.title" clearable>
                        </el-input>
                    </th>
                    <th v-if="columns.settings.show"></th>
                </tr>
            </thead>

            <transition-group name="flip-list" tag="tbody">
                <tr v-for="item in list" :key="item.id" class="cursor-pointer">
                    <td v-if="columns.id.show">{{ item.id }}</td>
                    <td v-if="columns.first_name.show">{{ item.first_name + ' ' + item.surname + ' ' + (item.patronymic ? item.patronymic : '')  }}</td>
                    <td v-if="columns.settings.show" class="settings-td">
                        <el-button v-can="'printCenter.show'" round @click="show(item)" size="mini" type="primary" icon="el-icon-view">{{ $t('message.show_0') }}</el-button>
                    </td>
                </tr>
            </transition-group>
        </table>
        <el-drawer title="Изменить тег" :visible.sync="drawerShow" size="80%" :drawer="drawerShow"
            @open="reopenShow = true" @close="reopenShow = false" @closed="emptyModel">
            <div>
                <crm-show :id="patientId" :reloadModel="reopenShow" @open="reopenShow = true"  @c-close="drawerShow = false"  @closed="emptyModel"></crm-show>
            </div>
        </el-drawer>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";
    import CrmShow from "./components/crm-show";
    export default {
    name: "conclusionsList",
        components:{
        CrmShow
        },
        data(){
            return {
                patientId:null,
                drawerShow: false,
                reopenShow: false,
                loadingData: false,
                list:[],
                filterForm: {
                    id: null,
                    created_at: null,
                    patient: ''
                }
            }
        },
        computed:{
            ...mapGetters({
                columns: "patients/columns",
                pagination: "conclusions/pagination",
                sort: "patients/sort",
                model: 'orders/relation_model',
            }),
        },
        async created(){
            this.debouncedGetAnswer = _.debounce(this.fetchData, 800)
        },
        async mounted(){
            if (this.list && this.list.length === 0)
              await  this.fetchData();
        },
        watch: {
            filterForm: {
                handler: function(newVal, oldVal) {  
                    this.debouncedGetAnswer()
                },
                deep: true
            }
        },
        methods: {
            ...mapActions({
                updateList: 'conclusions/printCenterHistory',
                updateSort: "conclusions/updateSort",
                updateFilter: "conclusions/updateFilter",
                updateColumn: "conclusions/updateColumn",
                updatePagination: "conclusions/updatePagination",
                showModel: 'conclusions/patientConculation',
                empty: 'conclusions/empty',
            }),
            fetchData() {
                const query = { ...this.filterForm, ...this.pagination, ...this.sort};
                if (!this.loadingData) {
                    this.loadingData = true;
                    this.updateList(query).then(res => {
                        this.list = res.data.data
                        this.loadingData = false
                    }).catch(err => {
                        this.loadingData = false
                    });
                }
            },
            async show(model){
                this.patientId = model.id;
                this.drawerShow = true;
            },
            emptyModel(){
                this.empty()
            },
        }
};
</script>
